<template>
  <teleport to="head">
    <title>
      Интернет магазин завода по производству тротуарной плитки
    </title>
  </teleport>
  <main class="contacts main">
    <div style="position:relative;overflow:hidden;">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2091.435874648588!2d37.74044328703993!3d55.97179508695357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b52fe635b5d92b%3A0xff1f853604edd64!2z0KHQvtCy0YXQvtC30L3QsNGPINGD0LsuLCAyMywg0JrQvtGA0LPQsNGI0LjQvdC-LCDQnNC-0YHQutC-0LLRgdC60LDRjyDQvtCx0LsuLCDQoNC-0YHRgdC40Y8sIDE0MTAzMw!5e1!3m2!1sru!2sam!4v1727466159044!5m2!1sru!2sam" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="maps">

      </iframe>
</div>
    <div class="contacts__content page__view">
      <h3 class="header-text">СК "Одинцовское"</h3>
      <p>Мы будем рады сотрудничать с Вами!</p>
      <Hr />
      <div class="main-contacts">
        <div class="main-contacts__addresses">
          <h3 class="header-text">Наш адрес</h3>
          <ul class="addresses__list">
            <li class="adresses_item">
              <b>Адрес:</b>
              <span>
                Город Мытищи посёлок Пироговский Совхозная улица 23
              </span>
            </li>

            <li class="adresses_item">
              <b>Тел.:</b>
              <a class="tel" href="tel:+79912640683">
                +7 991 26 30 683
              </a>
            </li>

            <li class="adresses_item">
              <b>Email:</b>
              <a
                class="main-contacts__addresses__mail"
                href="tratuarnayaplitkaru@gmail.com"
              >
              tratuarnayaplitkaru@gmail.com
              </a>
            </li>
          </ul>
          <p></p>
        </div>
        <div class="main-contacts__opening-hours">
          <h3 class="header-text">Часы работы</h3>
          <ul class="opening-hours__list">
            <li class="opening-hours__item">
              <p>Пн - Вс: с 09:00 до 22:00</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Hr from '@/components/Hr.vue';
export default {
  components: {
    Hr,
  },
};
</script>

<style scoped>
.contacts__content {
  padding: 40px 0;
}
.maps{
  width: 100%;
}
@media (max-width: 1050px) {
  .contacts__content {
    padding: 20px;
  }
}
.header-text {
  margin-bottom: 15px;
}
.main-contacts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

@media (max-width: 800px) {
  .main-contacts {
    grid-template-columns: 1fr;
  }
}

.opening-hours__list,
.addresses__list {
  list-style: none;
}

.tel,
.main-contacts__addresses__mail {
  color: inherit;
}
</style>
