<template>
  <hr class="hr" />
</template>
<style scoped>
.hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 30px 0;
}
</style>
